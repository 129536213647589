import { useTranslate } from "../../../../customHooks";
import { usePermissions } from '../../../../customHooks';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "../../../../components";
import { FormLayout, Input, SubmitButton } from "@panwds/react-form";
import { isEmpty, set } from 'lodash';
import { getFirewallTags } from "../../firewallsUtil";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, LoadingPanel } from "@panwds/react-ui";
import { FirewallEndpointsTable } from '../Edit';
import { EndpointsCreate } from "./EndpointComponents/EndpointsCreate";
import { EndpointsEdit } from "./EndpointComponents/EndpointsEdit";
import { validateAwsAccounts } from "../../../../utils/validate";
import { produce } from "immer";

type PanelType = "" | "createEndpoint" | "editEndpoint";
type EndpointAction = "" | "createEndpoint" | "deleteEndpoint" | "editEndpoint";

const FirewallEndpoints = (props: { firewallData: Record<string, any>, isLoading: boolean }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const [sidePanel, setSidePanel] = useState<PanelType>("");
    const [editEndpointRecord, setEditEndpointRecord] = useState<object>({});
    const [endpointAction, setEndpointAction] = useState<EndpointAction>("");
    const region = useMemo(() => new URLSearchParams(location.search).get('region'), [location.search]);
    const [updaFirewall] = useUpdateFirewallMutation();

    const config = {
        Firewall: ['FirewallId', 'EndpointServiceName', 'Region', 'Endpoints', 'AllowListAccounts', 'UpdateToken', 'DeploymentUpdateToken'],
    };

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config. 
        // Extracts fields needed for firewall endpoints form.
        const newStateWithConfig = extractFieldsWithConfig(props?.firewallData, config);
        return {
            Firewall: {
                ...newStateWithConfig.Firewall,
            }
        }
    }, [props?.firewallData]);

    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
        const transformedData = {
            ...formData.Firewall,
            AllowListAccounts: formData.Firewall.AllowListAccounts.split("," || []),
        } 
        if(formData.Firewall.AllowListAccounts === "") delete transformedData.AllowListAccounts;
        return transformedData;
    }, []);

    const formMethods = useForm({
        defaultValues: {
            Firewall: {
                ...transformFormDefaultValue?.Firewall,
                AllowListAccounts: transformFormDefaultValue?.Firewall?.AllowListAccounts?.join(",")
            }
        }
    });
    const {
        formState: { isDirty, isValid }, control, setValue, getValues, watch, reset,
    } = formMethods;

    const allowListedAwsAccounts = watch("Firewall.AllowListAccounts");
    const onSubmit = useCallback(
        async (data) => {
            try {
                const result = await updaFirewall({
                    payload: transformFormSubmitValue(data),
                    region: region || '',
                }).unwrap();
            }
            catch (error: any) {
                toast.error(`${error?.code}: ${error?.error}`, {toastId: "update-firewall-endpoints"});
            }
        }, [permissions]
    );

    const createEndpoint = useCallback(
        async (data) => {
            setValue("Firewall.Endpoints", [...getValues("Firewall.Endpoints"), data], {shouldDirty: true});
            setSidePanel("");
            setEndpointAction("createEndpoint");
        }, [setValue, getValues]
    );

    const deleteEndpoint = useCallback(
        (row) => {
            const currentEndpoints = getValues("Firewall.Endpoints");
            setValue("Firewall.Endpoints", currentEndpoints.filter(
                (endpoint) => endpoint?.EndpointId !== row?.EndpointId || endpoint?.SubnetId !== row?.SubnetId
            ), { shouldDirty: true });
            setEndpointAction("deleteEndpoint");
        }, [setValue, getValues]
    );

    const editEndpoint = useCallback(
        async (newEndpointData) => {
            const currentEndpoints = getValues("Firewall.Endpoints");
            const updatedState = produce(currentEndpoints, (draft) => {
                const index = draft.findIndex(item => item.EndpointId === newEndpointData?.EndpointId); // Find the index of the object to replace
                if (index !== -1) {
                    if (typeof newEndpointData?.Prefixes?.PrivatePrefix?.Cidrs === "string") {
                        newEndpointData.Prefixes.PrivatePrefix.Cidrs = newEndpointData.Prefixes.PrivatePrefix.Cidrs.split(",");
                    }
                  draft[index] = newEndpointData; // Replace the object at the found index with newObject
                }
            });
            setValue("Firewall.Endpoints", updatedState, {shouldDirty: true});
            setEndpointAction("editEndpoint");
            setSidePanel("");
        }, [setValue, getValues]
    );

    const setEditEndpointData = useCallback(
        async (row) => {
            setEditEndpointRecord(row);
            setSidePanel("editEndpoint");
        }, []
    );

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    return (
        <div>
            {/* <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                {props?.isLoading && <LoadingPanel />}
            </div> */}
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormLayout>
                        <Input
                            label={translate(`resources.firewallsV2.endpointServiceName`)}
                            name="Firewall.EndpointServiceName"
                            dataMetrics="cloudngfw-firewall-edit-endpoint-service-name"
                            description={translate(`resources.firewallsV2.firewallEndpointServiceNameEditDescription`)}
                            register={{ required: false }}
                            disabled
                        />
                        <Input
                            label={translate(`resources.firewallsV2.allowListedAccounts`)}
                            name="Firewall.AllowListAccounts"
                            dataMetrics="cloudngfw-firewall-edit-allowlisted-aws-accounts"
                            register={{ required: false, validate: validateAwsAccounts }}
                            description={translate(`resources.firewallsV2.firewallAllowlistedAwsAccountsEditDescription`)}
                        />
                        <Controller
                            name="Firewall.Endpoints"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <FirewallEndpointsTable key={endpointAction} endpointAction={endpointAction} allowListedAwsAccounts={allowListedAwsAccounts} setEditEndpointData={setEditEndpointData} deleteEndpoint={deleteEndpoint} setSidePanel={setSidePanel} {...field} label="Custom Field" />
                            )}
                        />

                        <div className="tw-flex tw-justify-end">
                            <Button addClassName="tw-mr-2" onClick={handleCancel} disabled={!isDirty}>
                                {translate(`generic.cancel`)}
                            </Button>
                            <SubmitButton disabled={!isDirty}>
                                {translate(`generic.save`)}
                            </SubmitButton>
                        </div>
                    </FormLayout>
                </form>
                {sidePanel === 'createEndpoint' && <EndpointsCreate
                    close={() => setSidePanel("")}
                    createEndpoint={createEndpoint}
                    allowlisteAwsAccounts={getValues('Firewall.AllowListAccounts')}
                />
                }
                {sidePanel === 'editEndpoint' && <EndpointsEdit
                    close={() => setSidePanel("")}
                    editEndpoint={editEndpoint}
                    editEndpointData={editEndpointRecord}
                />
            }
            </FormProvider>
        </div>
    );
};export default FirewallEndpoints;