export const SITE_SECTIONS = {
  // Certificate Object
  CreateCertificateObject: "CreateCertificateObject",
  DeleteCertificateObject: "DeleteCertificateObject",
  UpdateCertificateObject: "UpdateCertificateObject",
  DescribeCertificateObject: "DescribeCertificateObject",
  ListCertificateObject: "ListCertificateObject",
  // X Account
  PutXAccountRoleARN: "PutXAccountRoleARN",
  DescribeXAccountRole: "DescribeXAccountRole",
  ListOnboardedAccount: "ListOnboardedAccount",
  DeleteOnboardedAccount: "DeleteOnboardedAccount",
  // Link Account
  CreateLinkAccount: "CreateLinkAccount",
  DeleteLinkAccount: "DeleteLinkAccount",
  DescribeLinkAccount: "DescribeLinkAccount",
  ListLinkAccount: "ListLinkAccount",
  CreateUpdateToken: "CreateUpdateToken",
  // UserManagement
  CreateUser: "CreateUser",
  ListUsers: "ListUsers",
  DescribeUser: "DescribeUser",
  DeleteUser: "DeleteUser",
  UpdateUser: "UpdateUser",
  AddUserToSaaSRole: "AddUserToSaaSRole",
  RemoveUserFromSaaSRole: "RemoveUserFromSaaSRole",
  ListSaaSRoles: "ListSaaSRoles",
  // Subscription
  Subscribe: "Subscribe",
  ListSubscription: "ListSubscription",
  UpdateSubscription: "UpdateSubscription",
  // Firewall CRUD API
  CreateFirewall: "CreateFirewall",
  DeleteFirewall: "DeleteFirewall",
  DescribeFirewall: "DescribeFirewall",
  ListFirewalls: "ListFirewalls",
  UpdateFirewallDescription: "UpdateFirewallDescription",
  UpdateFirewallVersion: "UpdateFirewallVersion",
  AssociateSubnetMappings: "AssociateSubnetMappings",
  DisassociateSubnetMappings: "DisassociateSubnetMappings",
  // Association CRUD API
  AssociateGlobalRuleStack: "AssociateGlobalRuleStack",
  DisassociateGlobalRuleStack: "DisassociateGlobalRuleStack",
  AssociateRuleStack: "AssociateRuleStack",
  DisassociateRuleStack: "DisassociateRuleStack",
  // Tag API
  TagResource: "Tag Resource",
  UnTagResource: "UnTag Resource",
  ListTagsForResource: "ListTagsForResource",
  // Tag Firewall
  TagFirewall: "TagFirewall",
  UnTagFirewall: "UnTagFirewall",
  ListTagsForFirewall: "ListTagsForFirewall",
  // Tag RuleStack
  TagRulestack: "TagRulestack",
  UnTagRulestack: "UnTagRulestack",
  ListTagsForRulestack: "ListTagsForRulestack",
  // Log Profile API
  UpdateLogProfile: "UpdateLogProfile",
  DescribeLogProfile: "DescribeLogProfile",
  // RuleList CRUD API
  CreateSecurityRule: "CreateSecurityRule",
  DeleteSecurityRule: "DeleteSecurityRule",
  DescribeSecurityRule: "DescribeSecurityRule",
  ResetSecurityRuleCounters: "ResetSecurityRuleCounters",
  RefreshSecurityRuleCounters: "RefreshSecurityRuleCounters",
  ListSecurityRules: "ListSecurityRules",
  UpdateSecurityRules: "UpdateSecurityRules",
  DescribeSecurityRuleCounters: "DescribeSecurityRuleCounters",
  // RuleStack CRUD API
  CreateRuleStack: "CreateRuleStack",
  DeleteRuleStack: "DeleteRuleStack",
  DescribeRuleStack: "DescribeRuleStack",
  ListRuleStacks: "ListRuleStacks",
  UpdateRuleStack: "UpdateRuleStack",
  ListSecurityRuleLists: "ListSecurityRuleLists",
  // Commit Config API
  CommitRuleStack: "CommitRuleStack",
  ValidateRuleStack: "ValidateRuleStack",
  RevertRuleStack: "RevertRuleStack",
  DescribeCommitStatus: "DescribeCommitStatus",
  // Policy Read-Only API
  ListApplications: "ListApplications",
  DescribeApplication: "DescribeApplication",
  ListAppVersions: "ListAppVersions",
  DescribeAppVersions: "DescribeAppVersions",
  ListGeoLocation: "ListGeoLocation",
  // URL Category
  CreateCustomURLCategory: "CreateCustomURLCategory",
  DeleteCustomURLCategory: "DeleteCustomURLCategory",
  UpdateURLCategory: "UpdateURLCategory",
  DescribeURLCategory: "DescribeURLCategory",
  ListURLCategories: "ListURLCategories",
  // FQDN List (Address Object)
  CreateFqdnList: "CreateFqdnList",
  DeleteFqdnList: "DeleteFqdnList",
  UpdateFqdnList: "UpdateFqdnList",
  ListFqdnList: "ListFqdnList",
  DescribeFqdnList: "DescribeFqdnList",
  // Prefix List (Dynamic Object)
  CreatePrefixList: "CreatePrefixList",
  DeletePrefixList: "DeletePrefixList",
  UpdatePrefixList: "UpdatePrefixList",
  DescribePrefixList: "DescribePrefixList",
  ListPrefixList: "ListPrefixList",
  // Intelligent Feed (EDL)
  CreateIntelligentFeed: "CreateIntelligentFeed",
  DeleteIntelligentFeed: "DeleteIntelligentFeed",
  UpdateIntelligentFeed: "UpdateIntelligentFeed",
  DescribeIntelligentFeed: "DescribeIntelligentFeed",
  ListIntelligentFeed: "ListIntelligentFeed",
  // AntiVirus Exception
  CreateAntiVirusException: "CreateAntiVirusException",
  DeleteAntiVirusException: "DeleteAntiVirusException",
  UpdateAntiVirusException: "UpdateAntiVirusException",
  DescribeAntiVirusException: "DescribeAntiVirusException",
  // Vulnerability Exception
  CreateVulnerabilityException: "CreateVulnerabilityException",
  UpdateVulnerabilityException: "UpdateVulnerabilityException",
  DeleteVulnerabilityException: "DeleteVulnerabilityException",
  DescribeVulnerabilityException: "DescribeVulnerabilityException",
  // Spyware Exception
  CreateSpywareException: "CreateSpywareException",
  UpdateSpywareException: "UpdateSpywareException",
  DeleteSpywareException: "DeleteSpywareException",
  DescribeSpywareException: "DescribeSpywareException",
  // FileBlocking Exception
  CreateFileBlockingAction: "CreateFileBlockingAction",
  UpdateFileBlockingAction: "UpdateFileBlockingAction",
  DeleteFileBlockingAction: "DeleteFileBlockingAction",
  DescribeFileBlockingAction: "DescribeFileBlockingAction",
  // XAccount Role
  PutRoleARN: "PutRoleARN",
  DescribeRole: "DescribeRole",
  ListRoleARN: "ListRoleARN",
  // Programmatic Access
  CreateProgrammaticAccess: "CreateProgrammaticAccess",
  DeleteProgrammaticAccess: "DeleteProgrammaticAccess",
  DescribeProgrammaticAccess: "DescribeProgrammaticAccess",
    // Integrations
    CreatePanorama: "CreatePanorama",
    UpdatePanorama: "UpdatePanorama",
    // Support Information
    CreateACase: "CreateACase"
};

export const ROLES = {
  TenantAdministrator: 'TenantAdmin',
  LocalFirewallAdministrator: 'LocalFirewallAdmin',
  LocalRuleStackAdministrator: 'LocalRuleStackAdmin',
  GlobalRuleStackAdministrator: 'GlobalRuleStackAdmin',
  GlobalFirewallAdministrator: 'GlobalFirewallAdmin',
  TenantReader: 'TenantReader',
  SupportReader: 'SupportReader',
  InventoryAdmin: 'InventoryAdmin',
};

export const PERMISSIONS = {
  [ROLES.TenantAdministrator]: [
    // Firewall CRUD API
    SITE_SECTIONS.DescribeFirewall,
    SITE_SECTIONS.ListFirewalls,
    // Tag API
    SITE_SECTIONS.ListTagsForResource,
    // Log Profile API
    SITE_SECTIONS.DescribeLogProfile,
    // RuleList CRUD API
    SITE_SECTIONS.DescribeSecurityRule,
    SITE_SECTIONS.ListSecurityRules,
    SITE_SECTIONS.DescribeSecurityRuleCounters,
    // RuleStack CRUD API
    SITE_SECTIONS.ListRuleStacks,
    SITE_SECTIONS.DescribeRuleStack,
    SITE_SECTIONS.ListSecurityRuleLists,
    // Policy Read-Only API
    SITE_SECTIONS.ListApplications,
    SITE_SECTIONS.DescribeApplication,
    SITE_SECTIONS.ListAppVersions,
    SITE_SECTIONS.DescribeAppVersions,
    SITE_SECTIONS.ListGeoLocation,
    // URL Category
    SITE_SECTIONS.ListURLCategories,
    SITE_SECTIONS.DescribeURLCategory,
    // FQDN List (Address Object)
    SITE_SECTIONS.ListFqdnList,
    SITE_SECTIONS.DescribeFqdnList,
    // Prefix List (Dynamic Object)
    SITE_SECTIONS.ListPrefixList,
    SITE_SECTIONS.DescribePrefixList,
    // Intelligent Feed (EDL)
    SITE_SECTIONS.ListIntelligentFeed,
    SITE_SECTIONS.DescribeIntelligentFeed,
    // Certificate Object
    SITE_SECTIONS.ListCertificateObject,
    SITE_SECTIONS.DescribeCertificateObject,
    // Antivirus Exception
    SITE_SECTIONS.DescribeAntiVirusException,
    // Vulnerability Exception
    SITE_SECTIONS.DescribeVulnerabilityException,
    // Spyware Exception
    SITE_SECTIONS.DescribeSpywareException,
    // FileBlocking Exception
    SITE_SECTIONS.DescribeFileBlockingAction,
    // XAccount Role
    SITE_SECTIONS.PutXAccountRoleARN,
    SITE_SECTIONS.DescribeXAccountRole,
    SITE_SECTIONS.ListOnboardedAccount,
    SITE_SECTIONS.DeleteOnboardedAccount,
    // Link Account
    SITE_SECTIONS.CreateLinkAccount,
    SITE_SECTIONS.DeleteLinkAccount,
    SITE_SECTIONS.DescribeLinkAccount,
    SITE_SECTIONS.ListLinkAccount,
    SITE_SECTIONS.CreateUpdateToken,
    // User Management
    SITE_SECTIONS.CreateUser,
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    SITE_SECTIONS.DeleteUser,
    SITE_SECTIONS.UpdateUser,
    // Saas Role
    SITE_SECTIONS.AddUserToSaaSRole,
    SITE_SECTIONS.RemoveUserFromSaaSRole,
    SITE_SECTIONS.ListSaaSRoles,
    // Programmatic Access
    SITE_SECTIONS.CreateProgrammaticAccess,
    SITE_SECTIONS.DeleteProgrammaticAccess,
    SITE_SECTIONS.DescribeProgrammaticAccess,
    // Subscription Management
    SITE_SECTIONS.Subscribe,
    SITE_SECTIONS.ListSubscription,
    SITE_SECTIONS.UpdateSubscription,
  ],
  [ROLES.TenantReader]: [
    // Firewall CRUD API
    SITE_SECTIONS.DescribeFirewall,
    SITE_SECTIONS.ListFirewalls,
    // Tag API
    SITE_SECTIONS.ListTagsForResource,
    // Log Profile API
    SITE_SECTIONS.DescribeLogProfile,
    // RuleList CRUD API
    SITE_SECTIONS.DescribeSecurityRule,
    SITE_SECTIONS.ListSecurityRules,
    SITE_SECTIONS.DescribeSecurityRuleCounters,
    // RuleStack CRUD API
    SITE_SECTIONS.ListRuleStacks,
    SITE_SECTIONS.DescribeRuleStack,
    SITE_SECTIONS.ListSecurityRuleLists,
    // Policy Read-Only API
    SITE_SECTIONS.ListApplications,
    SITE_SECTIONS.DescribeApplication,
    SITE_SECTIONS.ListAppVersions,
    SITE_SECTIONS.DescribeAppVersions,
    SITE_SECTIONS.ListGeoLocation,
    // URL Category
    SITE_SECTIONS.ListURLCategories,
    SITE_SECTIONS.DescribeURLCategory,
    // FQDN List (Address Object)
    SITE_SECTIONS.ListFqdnList,
    SITE_SECTIONS.DescribeFqdnList,
    // Prefix List (Dynamic Object)
    SITE_SECTIONS.ListPrefixList,
    SITE_SECTIONS.DescribePrefixList,
    // Intelligent Feed (EDL)
    SITE_SECTIONS.ListIntelligentFeed,
    SITE_SECTIONS.DescribeIntelligentFeed,
    // Certificate Object
    SITE_SECTIONS.ListCertificateObject,
    SITE_SECTIONS.DescribeCertificateObject,
    // Antivirus Exception
    SITE_SECTIONS.DescribeAntiVirusException,
    // Vulnerability Exception
    SITE_SECTIONS.DescribeVulnerabilityException,
    // Spyware Exception
    SITE_SECTIONS.DescribeSpywareException,
    // FileBlocking Exception
    SITE_SECTIONS.DescribeFileBlockingAction,
    // XAccount Role
    SITE_SECTIONS.DescribeXAccountRole,
    SITE_SECTIONS.ListOnboardedAccount,
    // Link Account
    SITE_SECTIONS.DescribeLinkAccount,
    SITE_SECTIONS.ListLinkAccount,
    // User Management
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // Saas Role
    SITE_SECTIONS.ListSaaSRoles,
    // Programmatic Access
    SITE_SECTIONS.DescribeProgrammaticAccess,
    // User Role Mapping
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // Subscription Management
    SITE_SECTIONS.ListSubscription,
  ],
  [ROLES.LocalFirewallAdministrator]: [
    // Firewall CRUD API
    SITE_SECTIONS.CreateFirewall,
    SITE_SECTIONS.DeleteFirewall,
    SITE_SECTIONS.DescribeFirewall,
    SITE_SECTIONS.ListFirewalls,
    SITE_SECTIONS.UpdateFirewallDescription,
    SITE_SECTIONS.UpdateFirewallVersion,
    SITE_SECTIONS.AssociateSubnetMappings,
    SITE_SECTIONS.DisassociateSubnetMappings,
    // Association CRUD API
    SITE_SECTIONS.AssociateRuleStack,
    SITE_SECTIONS.DisassociateRuleStack,
    // Tag API
    SITE_SECTIONS.TagFirewall,
    SITE_SECTIONS.UnTagFirewall,
    SITE_SECTIONS.ListTagsForFirewall,
    //How to control for Rulestack?
    // Log Profile API
    SITE_SECTIONS.UpdateLogProfile,
    SITE_SECTIONS.DescribeLogProfile,
    // RuleList CRUD API
    SITE_SECTIONS.DescribeSecurityRule,
    SITE_SECTIONS.ResetSecurityRuleCounters,
    SITE_SECTIONS.RefreshSecurityRuleCounters,
    SITE_SECTIONS.DescribeSecurityRuleCounters,
    SITE_SECTIONS.ListSecurityRules,
    // RuleStack CRUD API
    SITE_SECTIONS.DescribeRuleStack,
    SITE_SECTIONS.ListRuleStacks,
    SITE_SECTIONS.ListSecurityRuleLists,
    // Commit Config API
    SITE_SECTIONS.DescribeCommitStatus,
    // Policy Read-Only API
    SITE_SECTIONS.ListApplications,
    SITE_SECTIONS.DescribeApplication,
    SITE_SECTIONS.ListAppVersions,
    SITE_SECTIONS.DescribeAppVersions,
    SITE_SECTIONS.ListGeoLocation,
    // URL Category
    SITE_SECTIONS.DescribeURLCategory,
    SITE_SECTIONS.ListURLCategories,
    // FQDN List (Address Object)
    SITE_SECTIONS.ListFqdnList,
    SITE_SECTIONS.DescribeFqdnList,
    // Prefix List (Dynamic Object)
    SITE_SECTIONS.DescribePrefixList,
    SITE_SECTIONS.ListPrefixList,
    // Intelligent Feed (EDL)
    SITE_SECTIONS.DescribeIntelligentFeed,
    SITE_SECTIONS.ListIntelligentFeed,
    // Certificate Object
    SITE_SECTIONS.DescribeCertificateObject,
    SITE_SECTIONS.ListCertificateObject,
    // AntiVirus Exception
    SITE_SECTIONS.DescribeAntiVirusException,
    // Vulnerability Exception
    SITE_SECTIONS.DescribeVulnerabilityException,
    // Spyware Exception
    SITE_SECTIONS.DescribeSpywareException,
    // FileBlocking Exception
    SITE_SECTIONS.DescribeFileBlockingAction,
    // XAccount Role
    SITE_SECTIONS.DescribeXAccountRole,
    SITE_SECTIONS.ListOnboardedAccount,
    //Link Account
    SITE_SECTIONS.DescribeLinkAccount,
    SITE_SECTIONS.ListLinkAccount,
    // User Management
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // User Role Mapping
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // Subscription Management
    SITE_SECTIONS.ListSubscription
  ],
  [ROLES.LocalRuleStackAdministrator]: [
    // Firewall CRUD API
    SITE_SECTIONS.DescribeFirewall,
    SITE_SECTIONS.ListFirewalls,
    // Association CRUD API
    SITE_SECTIONS.AssociateRuleStack,
    SITE_SECTIONS.DisassociateRuleStack,
    // Tag API
    SITE_SECTIONS.TagRulestack,
    SITE_SECTIONS.UnTagRulestack,
    SITE_SECTIONS.ListTagsForRulestack,
    // Log Profile API
    SITE_SECTIONS.DescribeLogProfile,
    // RuleList CRUD API
    SITE_SECTIONS.CreateSecurityRule,
    SITE_SECTIONS.DeleteSecurityRule,
    SITE_SECTIONS.DescribeSecurityRule,
    SITE_SECTIONS.ResetSecurityRuleCounters,
    SITE_SECTIONS.RefreshSecurityRuleCounters,
    SITE_SECTIONS.DescribeSecurityRuleCounters,
    SITE_SECTIONS.ListSecurityRules,
    SITE_SECTIONS.UpdateSecurityRules,
    // RuleStack CRUD API
    SITE_SECTIONS.CreateRuleStack,
    SITE_SECTIONS.DeleteRuleStack,
    SITE_SECTIONS.DescribeRuleStack,
    SITE_SECTIONS.ListRuleStacks,
    SITE_SECTIONS.UpdateRuleStack,
    SITE_SECTIONS.ListSecurityRuleLists,
    // Commit Config API
    SITE_SECTIONS.CommitRuleStack,
    SITE_SECTIONS.ValidateRuleStack,
    SITE_SECTIONS.RevertRuleStack,
    SITE_SECTIONS.DescribeCommitStatus,
    // Policy Read-Only API
    SITE_SECTIONS.ListApplications,
    SITE_SECTIONS.DescribeApplication,
    SITE_SECTIONS.ListAppVersions,
    SITE_SECTIONS.DescribeAppVersions,
    SITE_SECTIONS.ListGeoLocation,
    // URL Category
    SITE_SECTIONS.CreateCustomURLCategory,
    SITE_SECTIONS.DeleteCustomURLCategory,
    SITE_SECTIONS.UpdateURLCategory,
    SITE_SECTIONS.DescribeURLCategory,
    SITE_SECTIONS.ListURLCategories,
    // FQDN List (Address Object)
    SITE_SECTIONS.CreateFqdnList,
    SITE_SECTIONS.DeleteFqdnList,
    SITE_SECTIONS.UpdateFqdnList,
    SITE_SECTIONS.ListFqdnList,
    SITE_SECTIONS.DescribeFqdnList,
    // Prefix List (Dynamic Object)
    SITE_SECTIONS.CreatePrefixList,
    SITE_SECTIONS.DeletePrefixList,
    SITE_SECTIONS.UpdatePrefixList,
    SITE_SECTIONS.DescribePrefixList,
    SITE_SECTIONS.ListPrefixList,
    // Intelligent Feed (EDL)
    SITE_SECTIONS.CreateIntelligentFeed,
    SITE_SECTIONS.DeleteIntelligentFeed,
    SITE_SECTIONS.UpdateIntelligentFeed,
    SITE_SECTIONS.DescribeIntelligentFeed,
    SITE_SECTIONS.ListIntelligentFeed,
    // CertificateObject
    SITE_SECTIONS.CreateCertificateObject,
    SITE_SECTIONS.DeleteCertificateObject,
    SITE_SECTIONS.UpdateCertificateObject,
    SITE_SECTIONS.DescribeCertificateObject,
    SITE_SECTIONS.ListCertificateObject,
    // AntiVirus Exception
    SITE_SECTIONS.CreateAntiVirusException,
    SITE_SECTIONS.DeleteAntiVirusException,
    SITE_SECTIONS.UpdateAntiVirusException,
    SITE_SECTIONS.DescribeAntiVirusException,
    // Vulnerability Exception
    SITE_SECTIONS.CreateVulnerabilityException,
    SITE_SECTIONS.DeleteVulnerabilityException,
    SITE_SECTIONS.UpdateVulnerabilityException,
    SITE_SECTIONS.DescribeVulnerabilityException,
    // Spyware Exception
    SITE_SECTIONS.CreateSpywareException,
    SITE_SECTIONS.DeleteSpywareException,
    SITE_SECTIONS.UpdateSpywareException,
    SITE_SECTIONS.DescribeSpywareException,
    // FileBlocking Exception
    SITE_SECTIONS.CreateFileBlockingAction,
    SITE_SECTIONS.DeleteFileBlockingAction,
    SITE_SECTIONS.UpdateFileBlockingAction,
    SITE_SECTIONS.DescribeFileBlockingAction,
    // XAccount Role
    SITE_SECTIONS.DescribeXAccountRole,
    SITE_SECTIONS.ListOnboardedAccount,
    //Link Account
    SITE_SECTIONS.DescribeLinkAccount,
    SITE_SECTIONS.ListLinkAccount,
    // User Management
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // User Role Mapping
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // Subscription Management
    SITE_SECTIONS.ListSubscription
  ],
  [ROLES.GlobalRuleStackAdministrator]: [
    // Firewall CRUD API
    SITE_SECTIONS.DescribeFirewall,
    SITE_SECTIONS.ListFirewalls,
    // Association CRUD API
    SITE_SECTIONS.AssociateGlobalRuleStack,
    SITE_SECTIONS.DisassociateGlobalRuleStack,
    // Tag API
    SITE_SECTIONS.TagRulestack,
    SITE_SECTIONS.UnTagRulestack,
    SITE_SECTIONS.ListTagsForRulestack,
    // Log Profile API
    SITE_SECTIONS.DescribeLogProfile,
    // RuleList CRUD API
    SITE_SECTIONS.CreateSecurityRule,
    SITE_SECTIONS.DeleteSecurityRule,
    SITE_SECTIONS.DescribeSecurityRule,
    SITE_SECTIONS.ResetSecurityRuleCounters,
    SITE_SECTIONS.RefreshSecurityRuleCounters,
    SITE_SECTIONS.DescribeSecurityRuleCounters,
    SITE_SECTIONS.ListSecurityRules,
    SITE_SECTIONS.UpdateSecurityRules,
    // RuleStack CRUD API
    SITE_SECTIONS.CreateRuleStack,
    SITE_SECTIONS.DeleteRuleStack,
    SITE_SECTIONS.DescribeRuleStack,
    SITE_SECTIONS.ListRuleStacks,
    SITE_SECTIONS.UpdateRuleStack,
    SITE_SECTIONS.ListSecurityRuleLists,
    // Commit Config API
    SITE_SECTIONS.CommitRuleStack,
    SITE_SECTIONS.ValidateRuleStack,
    SITE_SECTIONS.RevertRuleStack,
    SITE_SECTIONS.DescribeCommitStatus,
    // Policy Read-Only API
    SITE_SECTIONS.ListApplications,
    SITE_SECTIONS.DescribeApplication,
    SITE_SECTIONS.ListAppVersions,
    SITE_SECTIONS.DescribeAppVersions,
    SITE_SECTIONS.ListGeoLocation,
    // URL Category
    SITE_SECTIONS.CreateCustomURLCategory,
    SITE_SECTIONS.DeleteCustomURLCategory,
    SITE_SECTIONS.UpdateURLCategory,
    SITE_SECTIONS.DescribeURLCategory,
    SITE_SECTIONS.ListURLCategories,
    // FQDN List (Address Object)
    SITE_SECTIONS.CreateFqdnList,
    SITE_SECTIONS.DeleteFqdnList,
    SITE_SECTIONS.UpdateFqdnList,
    SITE_SECTIONS.ListFqdnList,
    SITE_SECTIONS.DescribeFqdnList,
    // Prefix List (Dynamic Object)
    SITE_SECTIONS.CreatePrefixList,
    SITE_SECTIONS.DeletePrefixList,
    SITE_SECTIONS.UpdatePrefixList,
    SITE_SECTIONS.DescribePrefixList,
    SITE_SECTIONS.ListPrefixList,
    // Intelligent Feed (EDL)
    SITE_SECTIONS.CreateIntelligentFeed,
    SITE_SECTIONS.DeleteIntelligentFeed,
    SITE_SECTIONS.UpdateIntelligentFeed,
    SITE_SECTIONS.DescribeIntelligentFeed,
    SITE_SECTIONS.ListIntelligentFeed,
    // CertificateObject
    SITE_SECTIONS.CreateCertificateObject,
    SITE_SECTIONS.DeleteCertificateObject,
    SITE_SECTIONS.UpdateCertificateObject,
    SITE_SECTIONS.DescribeCertificateObject,
    SITE_SECTIONS.ListCertificateObject,
    // AntiVirus Exception
    SITE_SECTIONS.CreateAntiVirusException,
    SITE_SECTIONS.DeleteAntiVirusException,
    SITE_SECTIONS.UpdateAntiVirusException,
    SITE_SECTIONS.DescribeAntiVirusException,
    // Vulnerability Exception
    SITE_SECTIONS.CreateVulnerabilityException,
    SITE_SECTIONS.DeleteVulnerabilityException,
    SITE_SECTIONS.UpdateVulnerabilityException,
    SITE_SECTIONS.DescribeVulnerabilityException,
    // Spyware Exception
    SITE_SECTIONS.CreateSpywareException,
    SITE_SECTIONS.DeleteSpywareException,
    SITE_SECTIONS.UpdateSpywareException,
    SITE_SECTIONS.DescribeSpywareException,
    // FileBlocking Exception
    SITE_SECTIONS.CreateFileBlockingAction,
    SITE_SECTIONS.DeleteFileBlockingAction,
    SITE_SECTIONS.UpdateFileBlockingAction,
    SITE_SECTIONS.DescribeFileBlockingAction,
    // XAccount Role
    SITE_SECTIONS.DescribeXAccountRole,
    SITE_SECTIONS.ListOnboardedAccount,
    //Link Account
    SITE_SECTIONS.DescribeLinkAccount,
    SITE_SECTIONS.ListLinkAccount,
    // User Management
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // User Role Mapping
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // Subscription Management
    SITE_SECTIONS.ListSubscription
  ],
  [ROLES.GlobalFirewallAdministrator]: [
    // Firewall CRUD API
    SITE_SECTIONS.CreateFirewall,
    SITE_SECTIONS.DeleteFirewall,
    SITE_SECTIONS.DescribeFirewall,
    SITE_SECTIONS.ListFirewalls,
    SITE_SECTIONS.UpdateFirewallDescription,
    SITE_SECTIONS.UpdateFirewallVersion,
    SITE_SECTIONS.AssociateSubnetMappings,
    SITE_SECTIONS.DisassociateSubnetMappings,
    // Association CRUD API
    SITE_SECTIONS.AssociateGlobalRuleStack,
    SITE_SECTIONS.DisassociateGlobalRuleStack,
    // Tag API
    SITE_SECTIONS.TagFirewall,
    SITE_SECTIONS.UnTagFirewall,
    SITE_SECTIONS.ListTagsForFirewall,
    //How to control for Rulestack?
    // Log Profile API
    SITE_SECTIONS.UpdateLogProfile,
    SITE_SECTIONS.DescribeLogProfile,
    // RuleList CRUD API
    SITE_SECTIONS.DescribeSecurityRule,
    SITE_SECTIONS.ResetSecurityRuleCounters,
    SITE_SECTIONS.RefreshSecurityRuleCounters,
    SITE_SECTIONS.DescribeSecurityRuleCounters,
    SITE_SECTIONS.ListSecurityRules,
    // RuleStack CRUD API
    SITE_SECTIONS.CreateRuleStack,
    SITE_SECTIONS.DeleteRuleStack,
    SITE_SECTIONS.DescribeRuleStack,
    SITE_SECTIONS.ListRuleStacks,
    SITE_SECTIONS.UpdateRuleStack,
    // Commit Config API
    SITE_SECTIONS.DescribeCommitStatus,
    // Policy Read-Only API
    SITE_SECTIONS.ListApplications,
    SITE_SECTIONS.DescribeApplication,
    SITE_SECTIONS.ListAppVersions,
    SITE_SECTIONS.DescribeAppVersions,
    SITE_SECTIONS.ListGeoLocation,
    // URL Category
    SITE_SECTIONS.DescribeURLCategory,
    SITE_SECTIONS.ListURLCategories,
    // FQDN List (Address Object)
    SITE_SECTIONS.ListFqdnList,
    SITE_SECTIONS.DescribeFqdnList,
    // Prefix List (Dynamic Object)
    SITE_SECTIONS.DescribePrefixList,
    SITE_SECTIONS.ListPrefixList,
    // Intelligent Feed (EDL)
    SITE_SECTIONS.DescribeIntelligentFeed,
    SITE_SECTIONS.ListIntelligentFeed,
    // CertificateObject
    SITE_SECTIONS.DescribeCertificateObject,
    SITE_SECTIONS.ListCertificateObject,
    // AntiVirus Exception
    SITE_SECTIONS.DescribeAntiVirusException,
    // Vulnerability Exception
    SITE_SECTIONS.DescribeVulnerabilityException,
    // Spyware Exception
    SITE_SECTIONS.DescribeSpywareException,
    // FileBlocking Exception
    SITE_SECTIONS.DescribeFileBlockingAction,
    // XAccount Role
    SITE_SECTIONS.PutXAccountRoleARN,
    SITE_SECTIONS.DescribeXAccountRole,
    SITE_SECTIONS.ListOnboardedAccount,
    SITE_SECTIONS.DeleteOnboardedAccount,
    //Link Account
    SITE_SECTIONS.CreateLinkAccount,
    SITE_SECTIONS.DeleteLinkAccount,
    SITE_SECTIONS.DescribeLinkAccount,
    SITE_SECTIONS.ListLinkAccount,
    // User Management
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // User Role Mapping
    SITE_SECTIONS.ListUsers,
    SITE_SECTIONS.DescribeUser,
    // Subscription Management
    SITE_SECTIONS.ListSubscription
  ],
    [ROLES.SupportReader]: [
        // Programmatic Access
        SITE_SECTIONS.CreateProgrammaticAccess,
        // Subscription Management
        SITE_SECTIONS.UpdateSubscription,
        // Integration
        SITE_SECTIONS.CreatePanorama,
        // Support Information
        SITE_SECTIONS.CreateACase
    ],
    [ROLES.InventoryAdmin]: []
}
